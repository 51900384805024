.about {
    padding-top: 100px;
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.link {
    color: black;
}
.about_header {
    display: flex;
    align-items: center;
    gap: 43px;
    font-size: 48px;
}

.about_header img {
    width: 61px;
    height: 60px;
}

.grid {
    display: flex;
    margin-top: 70px;
    gap: 50px;
    max-width: 1000px;
    flex-wrap: wrap;
}

@media screen and (max-width: 1050px) {
    .grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

@media screen and (max-width: 800px) {
    .about_header {
        font-size: 24px;
        gap: 12px
    }

    .about_header img {
        width: 35px;
        height: 35px;
    }

    .grid {
        margin-top: 50px;
    }

}

