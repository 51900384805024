.hero {
    display: flex;
    flex-direction: column;
    padding-top: 200px;
    margin-left: 200px;
    margin-top:100px;
}

.red {
    color: #FE2C55;
}

.blue {
    color: #25F4EE;
}

.white {
    background-color: white;
    border-radius: 5px;
    padding: 15px 40px;
}

.hero_h1_black {
    color: #121212;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.hero_h1_white {
    max-width: 800px;
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.hero_p {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 60px;
}

.hero img {
    position: absolute;
    width: 612px;
    height: 585px;
    right: 100px;
    z-index: -1;
}

.blue_link {
    color: #25F4EE;
}

@media screen and (max-width: 1200px) {
    .hero {
        margin-left: 100px;
    }
}

@media screen and (max-width: 950px) {
    .hero_h1_white {
        font-size: 36px;
        max-width: 85%;
    }
    .white {
        font-size: 36px;
    }
    .hero_p {
        font-size: 18px;
    }
}

@media screen and (max-width: 800px) {
    .hero_h1_white {
        font-size: 24px;
    }

    .white {
        font-size: 24px;
    }

    .hero_h1_black {
        margin: 0;
        width: auto;
    }

    .hero_p {
        margin-top: 50px;
        max-width: 85%;
    }
}

@media screen and (max-width: 680px) {
    .white {
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {
    .white {
        font-size: 12px;
    }
    .hero {
        margin-left: 50px;
    }
}