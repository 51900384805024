.bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom_text {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    margin-top: 120px;
}

.link_white {
    color: white;
}

@media screen and (max-width: 650px) {
    .bottom_text {
        font-size: 12px;
    }

}

@media screen and (max-width: 575px) {
    .bottom_text {
        font-size: 12px;
        width: 50%;
        text-align: center;
    }

}