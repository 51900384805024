.footer {
    border-radius: 15px;
    background: #0E0E0E;
    max-width: 655px;
    padding: 30.14px 14.277px 14.18px 23.795px;
    display: flex;
    margin-top: 120px;
    margin-left: 120px;
}


.footer_p {
    color: rgba(255, 255, 255, 0.95);
    font-weight: 300;
    line-height: 34.7px;
    margin: 0;
    font-size: 23.794px;
}

.footer img {
    width: 15.8px;
    height: 15.8px;
}

.gray {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 5px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    color: white;
    height: 28.5px;
    padding: 0px 6.3px;
    border-radius: 4.759px;
    background: rgba(255, 255, 255, 0.20);
    max-width: 70px;
}

@media screen and (max-width: 950px) {
    .footer {
        margin: 0 auto;
    }
}

@media screen and (max-width: 800px) {
    .footer {
        max-width: 80%;

    }
    .footer_p {
        font-size: 14px;
        line-height: normal;
    }
    .gray img {
        width: 10px;
    }
    .gray {
        font-size: 14px;
        gap: 3px;
        max-width: 50px;
    }
}