.services {
    padding-top: 140px;
    margin-top: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.services img {
    width: 303px;
    height: auto;
}

.services_list {
    margin-top: 50px;
    display: flex;
    gap: 50px;
}

@media screen and (max-width: 1300px) {
    .services_list {
        flex-direction: column;
    }
}
@media screen and (max-width: 800px) {
    .services img {
        width: 150px;
    }
}