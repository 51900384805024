.header {
    position: relative;
    display: flex;
    padding: 30px 45px;
    background: rgba(49, 49, 49, 0.22);
    backdrop-filter: blur(2px);
    align-items: center;
    justify-content: space-between;
}

nav {
    display: flex;
}

.header img {
    width: 168px;
    height: 48px;
}

nav {
    display: flex;
    gap: 50px;
}

nav a {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
}

.sign_up {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: transparent;
}

.sign_up img {
    width: 152px;
    height: 51px;
}

.fixed_header {
    position: fixed;
    width: 100%;
    top: 0;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal_content {
    background-color: rgba(26, 25, 25, 0.72);
    backdrop-filter: blur(15px);
    margin: 10% auto;
    padding: 20px;
    border-radius: 10px;
    width: 562px;
    height: 524px;
}

.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.modal_heading {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    margin-top: 52px;
    margin-bottom: 35px;
}

form > input[type=email] {
    width: 379px;
    height: 45.5px;
    font-family: Montserrat;
    margin-bottom: 35px;
    border-radius: 10px;
    border: 2px solid #FE2C55;
    background: #D9D9D9;
    padding-left: 10px;
}

form > input[type=password] {
    width: 379px;
    height: 45.5px;
    font-family: Montserrat;
    margin-bottom: 35px;
    border-radius: 10px;
    border: 2px solid #36F8F2;
    background: #D9D9D9;
    padding-left: 10px;
}

.or_paragraph {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.red_circle {
    width: 14.5px;
    height: 14.5px;
    background-color: #FE2C55;
    border-radius: 100px;
}

.blue_circle {
    width: 14.5px;
    height: 14.5px;
    background-color: #36F8F2;
    border-radius: 100px;
}

.or {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.sign_in {
    color: #FFF;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    text-decoration-line: underline;
}

.modal_content-2 {
    background-color: rgba(26, 25, 25, 0.72);
    backdrop-filter: blur(15px);
    margin: 10% auto;
    padding: 20px;
    border-radius: 10px;
    width: 562px;
    height: 424px;
}

@media screen and (max-width: 1050px) {
    nav a {
        display: none;
    }
}

@media screen and (max-width: 680px) {
    .modal_content, .modal_content-2 {
        max-width: 90%;
    }
}

@media screen and (max-width: 450px) {
    #root > div.fixed_header > div > img {
        width: 100px;
        height: auto;
    }

    #root > div.fixed_header > div > nav > button > img {
        width: 100px;
        height: auto;
    }

    form > input[type=email] {
        width: 179px;
        height: 35.5px;
        font-family: Montserrat;
        margin-bottom: 25px;
        border-radius: 10px;
        border: 2px solid #FE2C55;
        background: #D9D9D9;
        padding-left: 10px;
    }

    form > input[type=password] {
        width: 179px;
        height: 35.5px;
        font-family: Montserrat;
        margin-bottom: 25px;
        border-radius: 10px;
        border: 2px solid #36F8F2;
        background: #D9D9D9;
        padding-left: 10px;
    }

    .modal_content, .modal_content-2 {
        width: 260px;
        height: 450px;
    }
}