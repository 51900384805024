.step_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.step_number {
    color: #FFF;
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
}

.step_heading {
    color: #FE2C55;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.step_p {
    margin: 0;
    max-width: 295px;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

@media screen and (max-width: 950px) {
    .step_by_step {
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 680px) {
    .step_by_step {
        display: flex;
        flex-direction: column;
    }
}