.service_card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.15);
    width: 398px;
    height: 378px;
    justify-content: center;
    align-items: center;
}

.service_heading{
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.service_p {
    width: 334px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.service_card img {
    width: 118px;
    height: auto;
}

@media screen and (max-width: 480px) {
    .service_heading {
        font-size: 16px;
    }
    .service_p {
        font-size: 12px;
        max-width: 70%;
    }
    .service_card {
        width: 250px;
    }
}