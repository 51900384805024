.glowings {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -3;
}

.image1 {
    width: auto;
    height: 878px;
    margin-right: 0px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -3;
}

.image2 {
    width: auto;
    height: 1417px;
    position: absolute;
    left: 0;
    top: 275px;
    z-index: -3;
}

.image3 {
    width: auto;
    height: 1417px;
    position: absolute;
    left: 0;
    top: 2165px;
    z-index: -3;
}

.image4 {
    width: auto;
    height: 521px;
    position: absolute;
    right: 0;
    top: 2262px;
    z-index: -3;
}

@media screen and (max-width: 580px) {
    #root > div.glowings > img.image3 {
        height: 700px;
        top: 1600px;
    }

    .image2 {
        height: 600px;
    }
}