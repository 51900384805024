.white_box {
    width: 470px;
    height: 240px;
    display: flex;
    gap: 32px;
    background-color: white;
    border-radius: 10px;
}

.red_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107px;
    height: 47px;
    background-color: #FE2C55;
    margin-top: 19px;
    margin-left: 19px;
    border-radius: 4.4px;
}

.grid_number {
    margin: 0;
    color: #FFF;
    text-align: center;
    font-size: 14.3px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.content {
    display: flex;
    flex-direction: column;
}

.h2_header {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    max-width: 280px;
}

.p_content {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    max-width: 270px;
}

@media screen and (max-width: 500px) {
    .white_box {
        width: 320px;
        height: auto;
        padding-bottom: 10px;
    }
    .p_content {
        max-width: 220px;
    }

    .h2_header {
        font-size: 18px;
        max-width: 85%;
    }
    .red_box {
        width: 50px;
    }
}