.working {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    margin-top: 300px;
    flex-direction: column;
    align-items: center;
}

.working_heading{
    font-size: 48px;
    font-weight: 700;
}

.working_p {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    max-width: 945px;
    margin-top: 45px;
}

.step_by_step {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    gap: 50px;
}

@media screen and (max-width: 1050px) {
    .working_p {
        max-width: 85%;
    }

}
@media screen and (max-width: 800px) {
    .working_p {
        font-size: 16px;
        max-width: 80%;
    }

    .working_heading{
        font-size: 24px;
    }

    .working {
        padding: 0;
    }

}